import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class CandidateService {
    constructor(private http: HttpClient) {}

    createCandidate(creatableCandidate: CreatableCandidate): Observable<{ assessmentSessionUrl: string }> {
        return this.http.post<{ assessmentSessionUrl: string }>(`${environment.backendUrl}/candidates`, creatableCandidate);
    }

    getCandidate(id: string): Observable<GetableCandidate> {
        return this.http.get<GetableCandidate>(`${environment.backendUrl}/candidates/${id}`);
    }

    updateCandidate(id: string, candidate: Candidate): Observable<void> {
        return this.http.put<void>(`${environment.backendUrl}/candidates/${id}`, candidate);
    }

    updateAvatar(id: string, blob: Blob, fileName: string): Observable<void> {
        let formdata = new FormData();
        formdata.append('file', blob, `${fileName}`);
        return this.http.post<void>(`${environment.backendUrl}/candidates/${id}/avatar`, formdata);
    }

    addAttachment(candidateId: string, file: File): Observable<void> {
        const url = new URL(`/candidates/${candidateId}/attachments`, environment.oldBackendUrl);
        url.searchParams.append('type', 'certificate');

        const formData = new FormData();
        formData.set('file', file);

        return this.http.post<void>(url.toString(), formData);
    }

    createFormSubmission(formSubmission: ContactFormSubmission): Observable<void> {
        return this.http.post<void>(`${environment.backendUrl}/contact-form/submission`, formSubmission);
    }

    createRatingFormSubmission(ratingFormSubmission: RatingFormSubmission): Observable<void> {
        return this.http.post<void>(`${environment.oldBackendUrl}/rating-form/submission`, ratingFormSubmission);
    }

    createVideoSession(candidateId: string): Observable<void> {
        return this.http.post<void>(
            `${environment.oldBackendUrl}/hireflix/administration/interview-by-candidate/recreate?candidate=${candidateId}`,
            {}
        );
    }
}

export interface CreatableCandidate {
    name: string;
    email: string;
    job: string;
    organization: string;
    returnAssessmentSession?: boolean;
    createAssessmentSession: boolean;
    preferredLanguage: string;
    context?: CandidateCreateContext;
}

export interface CandidateCreateContext {
    firstVisitDate?: string;
    firstVisitPage?: string;
}

export interface Candidate {
    gender: string;
    firstName: string;
    lastName: string;
    birthdate: string;
    addressLine1: string;
    addressLine2?: string;
    postalCode: string;
    city: string;
    state?: string;
    country?: string;
    phone?: string;
    experiences: Experience[];
    hasEmploymentPermit: boolean;
    hasAcademicDegree: boolean;
    hasProfessionalEducation: boolean;
    hasHigherEducationEntranceQualification: boolean;
    hasDrivingLicence: boolean;
    germanLanguageKnowledge: boolean;
    source?: string;
}

export interface GetableCandidate extends Candidate {
    id: string;
    name: string;
    isAllowedToUpdate: boolean;
    avatar?: string;
    acceptFileUpload: boolean;
    uploadedFiles: UploadedFile[];
}

export interface Experience {
    id?: string;
    title: string;
    company: string;
    description: string;
    startDate: string;
    endDate?: string;
    type: ExperienceType;
}

export enum ExperienceType {
    Professional = 'professional',
    Education = 'education'
}

export interface ContactFormSubmission {
    name: string;
    email: string;
    phone: string;
    company: string;
    context?: object;
}

export interface RatingFormSubmission {
    name: string;
    message: string;
    rating: number;
    context?: object;
}

export interface UploadedFile {
    id?: string;
    name: string;
}
