import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class NavigationService {
    theme: Subject<NavigationTheme> = new Subject<NavigationTheme>();

    constructor() {}
}

export type NavigationTheme = 'Light' | 'Pink';
