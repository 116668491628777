import { Component, OnInit } from '@angular/core';
import { LinkField } from '@prismicio/types';
import { GlobalContent, GlobalContentService } from '../../../services/global-content.service';
import { NavigationService, NavigationTheme } from '../../../services/navigation.service';
import { ApplicationModalService } from '../application-modal/application-modal.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
    logoLink?: LinkField;
    globalContent?: GlobalContent | null;

    theme: NavigationTheme = 'Pink';
    routeLanguagePrefix = '';
    isCollapsed = false;

    constructor(
        private globalContentService: GlobalContentService,
        private navigationService: NavigationService,
        private applicationModalService: ApplicationModalService
    ) {}

    ngOnInit(): void {
        this.globalContentService.getGlobalContent().subscribe((globalContent) => {
            this.globalContent = globalContent;
            this.logoLink = globalContent?.logo_link;
        });
        this.navigationService.theme.subscribe((theme) => {
            this.theme = theme;
        });
    }

    collapse(): void {
        if (window.innerWidth >= 1400) {
            return;
        }

        this.isCollapsed = !this.isCollapsed;
    }

    openApplicationModal(): void {
        this.collapse();
        this.applicationModalService.openModal();
    }
}
