import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LinkModule } from '../link/link.module';
import { NavigationComponent } from './navigation.component';

const modules = [CommonModule, RouterModule, TranslateModule, LinkModule, LinkModule];

const components = [NavigationComponent];

@NgModule({
    imports: [...modules, BsDropdownModule, CollapseModule],
    declarations: [...components],
    providers: [],
    exports: [...components]
})
export class NavigationModule {}
