<nav class="navbar navbar-expand-xl" [class]="theme">
    <div class="container py-2 py-xl-4 px-xl-0">
        <app-link [link]="logoLink" cssClass="navbar-brand d-flex align-items-center">
            <img src="./assets/images/logo_weiss.svg" alt="gotoitcareer Logo" style="height: 35px" />
        </app-link>
        <button
            class="navbar-toggler"
            type="button"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            (click)="collapse()"
        >
            <img [src]="isCollapsed ? '/assets/images/close.svg' : '/assets/images/menu.svg'" alt="Menu" />
        </button>
        <div class="collapse navbar-collapse" id="navbarNav" [class.show]="isCollapsed">
            <ul class="navbar-nav d-flex align-items-center ps-xl-5">
                <ng-container *ngIf="globalContent?.body as sliceZone">
                    <ng-container *ngFor="let slice of $any(sliceZone)">
                        <ng-container *ngIf="slice.slice_type === 'menu-item'">
                            <li class="nav-item mt-5 mt-xl-0 me-xl-5">
                                <ng-container *ngIf="slice.items.length > 0; else basicLink">
                                    <!-- Desktop: Dropdown -->
                                    <div class="btn-group d-none d-xl-flex" dropdown>
                                        <ng-container *ngIf="slice?.primary?.link?.uid">
                                            <app-link cssClass="menu-item" [link]="slice.primary.link">
                                                {{ slice.primary.title }}
                                            </app-link>
                                        </ng-container>
                                        <button
                                            id="button-basic"
                                            dropdownToggle
                                            type="button"
                                            class="button-unstyled dropdown-toggle dropdown-toggle-split ps-2"
                                            aria-controls="dropdown-basic"
                                        >
                                            <span class="me-2" *ngIf="!slice?.primary?.uid || !slice?.primary?.url">{{
                                                slice.primary.title
                                            }}</span>
                                            <span class="caret"></span>
                                        </button>
                                        <ul *dropdownMenu class="dropdown-menu background-black p-3" role="menu">
                                            <li role="menuitem" *ngFor="let menuItem of slice.items">
                                                <app-link
                                                    [link]="$any(menuItem).link"
                                                    routerLinkActive="active-link"
                                                    (click)="collapse()"
                                                    [cssClass]="'nav-dropdown dropdown-item color-white'"
                                                >
                                                    {{ $any(menuItem).title }}
                                                </app-link>
                                            </li>
                                        </ul>
                                    </div>

                                    <!-- Mobile: Collapse -->
                                    <div class="d-xl-none text-center">
                                        <button
                                            type="button"
                                            class="button-unstyled dropdown-toggle"
                                            (click)="slice.primary.isOpen = !slice.primary.isOpen"
                                            [attr.aria-expanded]="!!slice.primary.isOpen"
                                            aria-controls="collapseBasic"
                                        >
                                            {{ slice.primary.title }}
                                            <span class="caret"></span>
                                        </button>

                                        <div id="collapseBasic" [collapse]="!slice.primary.isOpen">
                                            <ul class="list-unstyled">
                                                <li class="mt-4" *ngFor="let menuItem of slice.items">
                                                    <app-link
                                                        [link]="$any(menuItem).link"
                                                        routerLinkActive="active-link"
                                                        (click)="collapse()"
                                                    >
                                                        {{ $any(menuItem).title }}
                                                    </app-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-template #basicLink>
                                    <app-link
                                        [link]="slice.primary.link"
                                        [anker]="slice?.primary?.anker"
                                        routerLinkActive="active-link"
                                        (click)="collapse()"
                                        [cssClass]="'menu-link'"
                                    >
                                        {{ slice.primary.title }}
                                    </app-link>
                                </ng-template>
                            </li>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ul>
            <ul class="navbar-nav d-flex align-items-center ms-auto">
                <li class="menu-link me-xl-3 mt-5 mt-xl-0 d-xl-none">
                    <a routerLink="/" class="mx-2"> DE </a>
                    |
                    <a routerLink="/en" class="mx-2"> EN </a>
                </li>
                <li class="mt-5 mt-xl-0">
                    <a href="https://www.youtube.com/@gotoitcareer" target="_blank" rel="noopener nofollow" class="mx-3 ms-xl-0 me-xl-4">
                        <img src="./assets/images/youtube_black.svg" alt="YouTube Icon" class="icon-small" />
                    </a>
                    <a href="https://www.tiktok.com/@gotoitcareer/" target="_blank" rel="noopener nofollow" class="mx-3 ms-xl-0 me-xl-4">
                        <img src="./assets/images/tik-tok.svg" alt="TikTok Icon" class="icon-small" />
                    </a>
                    <a href="https://www.instagram.com/gotoitcareer/" target="_blank" rel="noopener nofollow" class="mx-3 ms-xl-0 me-xl-4">
                        <img src="./assets/images/instagram_black.svg" alt="Instagram Icon" class="icon-small" />
                    </a>
                </li>
                <li class="mt-3 mt-xl-0 mt-5 mt-xl-0 prominent">
                    <button class="btn btn-black" (click)="openApplicationModal()">
                        {{ 'GLOBAL.APPLY_NOW' | translate }}
                    </button>
                </li>
            </ul>
        </div>
    </div>
</nav>
