import { DOCUMENT, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, ActivationEnd, Router, Scroll } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IdleMonitorService } from '@scullyio/ng-lib';
import { delay, filter } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    constructor(
        private idle: IdleMonitorService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private meta: Meta,
        @Inject(DOCUMENT) private document: Document,
        private route: ActivatedRoute,
        private viewportScroller: ViewportScroller
    ) {
        this.setupInternalization();
        this.router.events
            .pipe(
                filter((e: any): e is Scroll => e instanceof Scroll),
                delay(0)
            )
            .subscribe((e: any) => {
                if (e.position) {
                    viewportScroller.scrollToPosition(e.position);
                } else if (e.anchor) {
                    try {
                        const element = document.querySelector(`#${e.anchor}`);
                        if (element) {
                            element.scrollIntoView({ behavior: 'auto', block: 'start' });
                        }
                    } catch (e) {}
                }
            });
    }

    ngAfterViewInit(): void {
        // @ts-ignore
        window['AOS'].init();
    }

    ngOnInit() {
        this.saveFirstVisitPage();
    }

    private saveFirstVisitPage() {
        if (!localStorage.getItem('firstVisitPage')) {
            localStorage.setItem('firstVisitPage', location.href);
            localStorage.setItem('firstVisitDate', new Date().toLocaleDateString());
        }
    }

    private setupInternalization() {
        this.router.events.subscribe((event) => {
            if (event instanceof ActivationEnd) {
                this.translate.use(event.snapshot.data['language']);
            }
        });
    }
}
